import PageTitle from '../PageTitle/PageTitle';

const PageNotFound = () => {
  return (
    <div>
      <PageTitle>404 NOT FOUND</PageTitle>
    </div>
  );
};

  export default PageNotFound;