import Hero from "../Hero/Hero";
import Lists from '../Lists/Lists';

const Home = () => (
  <div>
    <Hero />
    <Lists />
  </div>
);

export default Home;